.wconstrain {
    margin-left: auto;
    margin-right: auto;

    max-width: calc(100% - 2rem);

    @include breakpoint(large) {
        &.w_l {
            width: rem-calc(1170);
        }
        &.w_m {
            width: rem-calc(965);
        }
        &.w_s {
            width: rem-calc(870);
        }
        /*
        &.w_m {
            //https://stackoverflow.com/questions/62280339/css-min-function-to-sass
            --temp0: calc(100% - 24rem);
            width: min(70rem, var(--temp0));
        }
        */
    }
}
