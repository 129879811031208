.zitat {
    padding-top:var(--sectionpaddingtb);
    padding-bottom:var(--sectionpaddingtb);

    text-align: center;

    .text {
        @include font-size-pt(50*0.75, 50);
        font-weight: 500;
        line-height: math.div(50, 50);
        letter-spacing: 0em;
        text-transform: uppercase;
    }
    .author::before {
        content:"";
        display: block;
        width:rem-calc(110);
        height:3px;
        background-color: $brown;
        border-radius: 999px;
        margin:1.6em auto 1.3em auto;
    }
}