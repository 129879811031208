footer {
  @include typo-footer;

  padding: rem-calc(20 0);
  
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      //color: darken($white,15%);
    }
  }

  .ctrlinks {
    .links a {
      display: block;
      font-weight:700;
    }
  }

  @include breakpoint(large) {
    .wconstrain {
      display:flex;
      justify-content:space-between;
    }
  }
  @include breakpoint(medium down) {
    .ctrlinks {
      margin-top:1em;
    }
  }
}
