@use "sass:math";

//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

body {
  //Dynamic Font Size (mobile, desktop)
  //@include font-size-pt(20*0.75, 20);
  @include font-size-pt(18, 20); //Issue #5
  font-weight: 400;
}

a:focus {
  outline: none;
}

@mixin typo-maintitle {
  @include font-size-pt(50*0.75, 50);
  font-weight: 500;
  line-height: math.div(60, 50);
  letter-spacing: 0em;
  text-transform: uppercase;
}

@mixin typo-leadtext {
  font-size:inherit;
  font-weight:700;
  line-height: inherit;
  letter-spacing:inherit;
}

@mixin typo-footer {
  //@include font-size-pt(17*0.75, 17);
  @include font-size-pt(15, 17); //Issue #5
  font-weight: 400;
  line-height: math.div(22, 17);
  letter-spacing: 0em;
}

h1 {
  @include typo-maintitle;
  margin-bottom:0.8em;
}