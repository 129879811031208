.h-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $light-gray;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  &.opaque {
    @include shadow(0 2px 7px 0 rgba($black, 0.1));
  }
}

@include breakpoint(small down) {
  #menu-desktop {
    display:none;
  }
}