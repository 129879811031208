// html.sr {
//   .fade-in-interval, .fade-in-news, .fade-in-bottom {
//     visibility: hidden;
//   }
// }

@mixin reset-list {
    margin: 0 !important;
    padding: 0;
    list-style: none;

    li::before {
        display:none;
    }
}