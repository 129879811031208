.textwithimage {
    .ctrtext {
        background-color: #DACABC;
        color:white;

        padding-top:var(--sectionpaddingtb);
        padding-bottom:var(--sectionpaddingtb);
        
        padding-left:2em;
        padding-right:2em;
        box-sizing: border-box;

        text-align: center;

        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .icon {
            width:rem-calc(50);
            height:rem-calc(50);
        }
    }

    .ctrimage {
        z-index:-1;

        background-size:cover;
        background-position: center center;
    }

    h2 {
        @include typo-leadtext;
        margin-bottom:$global-lineheight*1em;
    }

    .button {
        --btncolor:white;
        --btnbgcolorhover:white;
        --btncolorhover:#DACABC;
    }

    @include breakpoint(large) {
        position: relative;
        .ctrimage {
            position: absolute;
            left:0;
            top:0;
            width: 100%;
            height: 100%;
        }

        &.textpos-left .ctrtext {
            width:50%;
        }
        &.textpos-right .ctrtext {
            width:50%;
            margin-left: 50%;
        }
    }

    @include breakpoint(medium down) {
        .ctrimage {
            aspect-ratio: 1;
        }
        .wconstrain {
            max-width: none;
        }
    }
}

