#menuicon {
    position: fixed;
    left:var(--fixeditemmargin);
    top:var(--fixeditemmargin);

    a {
        display: block;
        padding:1em;
        margin:-1em;
    }

    img {
        display: block;
        width: rem-calc(18);
    }
}

#fixmenu {
    position: fixed;
    right:var(--fixeditemmargin);
    top:var(--fixeditemmargin);
    
    text-align: right;

    ul {
        @include reset-list;
    }
    a {
        display: block;
        color:$brown;
        margin-bottom:0.25em;
    }
    li.active a {
        font-weight: 700;
    }
}