@use 'sass:math';

.textwithicon {
    text-align: center;

    padding-top:var(--sectionpaddingtb);
    padding-bottom:var(--sectionpaddingtb);

    .icon {
        width:rem-calc(50);
        height:rem-calc(50);
    
        margin:0 auto;
    }

    p:last-child {
        margin-bottom:0;
    }

    h1 {
    }
    h2 {
        @include typo-leadtext;
        margin-bottom:$global-lineheight*1em;
    }

    h3 {
        @include font-size-pt(30*0.75, 30);
        font-weight: 700;
        line-height: math.div(30, 30);
        letter-spacing: 0em;
        text-transform: uppercase;
    }

    .textafter {
        margin-top:$global-lineheight*2em;
    }

    @include breakpoint (large) {
        .cols {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            gap: rem-calc(40);

            text-align: left;

            margin-top:$global-lineheight*2em;
        }
    }
    @include breakpoint (medium down) {
        .cols .col2 {
            margin-top:$global-lineheight*1em;
        }
        h3 {
            margin-top:$global-lineheight*2em;
        }
    }

    ul {
        @include reset-list;
    }
    li + li {
        border-top:1px solid currentColor;
        $lh2: math.div($global-lineheight, 2) * 1em;
        margin-top:$lh2;
        padding-top:calc($lh2 - 1px);
    }
}