.button {
  --btnbgcolor:transparent;
  --btncolor:#{$brown};
  --btnbgcolorhover:#{$brown};
  --btncolorhover:white;

  letter-spacing: 0em;
  font-weight: bold;
  padding:0 1em;
  
  background-color: var(--btnbgcolor);
  color:var(--btncolor);
  border:2px solid var(--btncolor);

  transition:color 0.2s, background-color 0.2s;

  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background-color: var(--btnbgcolorhover);
    color:var(--btncolorhover);
  }
}
