@use "sass:math";

.servicelist {
    text-align: center;

    padding-top:var(--sectionpaddingtb);
    padding-bottom:var(--sectionpaddingtb);

    .items {
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        .item {
            width:33.333%;
            padding:rem-calc(20);

            @include breakpoint(medium down) {
                width:50%;
            }
            @include breakpoint(small down) {
                width:100%;
            }
        }
        /*
        display:grid;
        grid-template-columns: repeat(4, 1fr);
        gap:rem-calc(20);
        @include breakpoint(medium down) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint(small down) {
            grid-template-columns: repeat(1, 1fr);
        }
        */
    }
    .titleicon {
        width:rem-calc(50);
        height:rem-calc(50);
        margin:0 auto;
    }
    .item {
        .colorbg {
            background-image: url('/assets/app/images/servicebg.webp');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;

            aspect-ratio: 1 / 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .icon {
            width:rem-calc(50);
            height:rem-calc(50);
        }
        h2 {
            @include font-size-pt(30*0.75, 30);
            font-weight: 500;
            line-height: math.div(30, 30);
            letter-spacing: 0em;

            margin: 0;

            max-width: 90%;
        }
    }
    .button {
        margin-top:2em;
    }
}