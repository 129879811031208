#totopbutton {
    position:fixed;
    right:var(--fixeditemmargin);
    bottom:var(--fixeditemmargin);

    cursor: pointer;

    img {
        display: block;
        width:rem-calc(40);
    }
}

#totopbutton {
    opacity: 0;
    transition: opacity 0.2s;
    &.visible {
        opacity: 1;
    }
}
