@use "sass:math";

.herostart {
    height:100vh;
    height:100lvh;

    position: relative;

    background-color: #FBF7F3;
    background-size: cover;
    background-position: center center;

    text-align: center;

    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .logo {
        width:rem-calc(370);
        max-width:80vw;
        display:block;
    }

    .scrolldownindicator {
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        top:calc(100vh - rem-calc(100));
        top:calc(100svh - rem-calc(100));

        cursor: pointer;

        .arrowimg {
            display:block;
            width:rem-calc(20);
            margin:1em auto 0 auto;
        }
    }

    .title {
        @include typo-maintitle;
        
        margin-top:0.75em;
    }
    .subtitle {
        @include font-size-pt(25*0.75, 25);
        font-weight: 400;
        line-height: math.div(33, 25);
        letter-spacing: 0em;

        margin-top:0.2em;
    }

    &.layout-contpage {
        .logo {
            visibility:hidden;
        }
        .subtitle {
            visibility: hidden;
        }
        /*
        .title::after {
            content:"";
            display: block;
            width:rem-calc(50);
            height:4px;
            background-color: $brown;
            border-radius: 999px;
            transform: rotate(-5deg);
            margin:0 auto;
        }
        */
    }

    @media (max-height:700px) and (min-aspect-ratio:0.6) {
        .scrolldownindicator {
            display:none;
        }
    }
}